/* Global Styles */
:root {
  --primary-color: #d32f2f;
  --secondary-color: #b71c1c;
  --accent-color: #212121;
  --text-color: #212121;
  --text-light: #484848;
  --text-lighter: #757575;
  --bg-color: #fff;
  --bg-light: #fff8f8;
  --bg-dark: #8e0000;
  --border-color: #e0e0e0;
  --container-width: 1200px;
  --spacing-xs: 0.5rem;
  --spacing-sm: 1rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;
  --border-radius: 8px;
  --shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--bg-color);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: var(--spacing-sm);
}

a {
  text-decoration: none;
  color: var(--primary-color);
  transition: var(--transition);
}

a:hover {
  color: var(--secondary-color);
}

p {
  margin-bottom: var(--spacing-sm);
}

ul, ol {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 0 var(--spacing-md);
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: var(--spacing-lg);
  position: relative;
  padding-bottom: var(--spacing-sm);
}

.section-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background-color: var(--accent-color);
  border-radius: 2px;
}

.section-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto var(--spacing-xl);
  color: var(--text-light);
  font-size: 1.1rem;
}

/* Header */
.main-header {
  background-color: var(--bg-color);
  box-shadow: var(--shadow);
  position: sticky;
  top: 0;
  z-index: 100;
  padding: var(--spacing-sm) 0;
  width: 100%;
}

.main-header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 60px;
  width: auto;
  object-fit: contain;
}

.logo h1 {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 0;
}

.main-nav ul {
  display: flex;
  gap: var(--spacing-md);
}

.main-nav a {
  color: var(--text-color);
  font-weight: 500;
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius);
}

.main-nav a:hover, .main-nav a.active {
  color: var(--primary-color);
}

.main-nav a.cta-button {
  background-color: var(--primary-color);
  color: white;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius);
}

.main-nav a.cta-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-2px);
}

/* Hero Section - Clean, minimal design with simple floating icons */
.hero {
  background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
  color: white;
  padding: var(--spacing-xl) 0;
  text-align: center;
  position: relative;
  overflow: hidden;
}

/* Create subtle background gradient */
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: 
    radial-gradient(circle at 85% 15%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100px),
    radial-gradient(circle at 15% 85%, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100px);
}

/* Primary education icons layer - SIMPLIFIED */
.hero-math-symbols {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='600' height='600' viewBox='0 0 600 600' fill='none'%3E%3Ctext x='80' y='120' font-family='Arial' font-size='60' fill='rgba(255,255,255,0.18)' transform='rotate(5 80,120)'%3Eπ%3C/text%3E%3Ctext x='450' y='250' font-family='Arial' font-size='70' fill='rgba(255,255,255,0.18)' transform='rotate(-5 450,250)'%3E∑%3C/text%3E%3Ctext x='150' y='450' font-family='Arial' font-size='80' fill='rgba(255,255,255,0.18)' transform='rotate(3 150,450)'%3E√%3C/text%3E%3Ctext x='380' y='380' font-family='Arial' font-size='65' fill='rgba(255,255,255,0.18)' transform='rotate(-3 380,380)'%3E∞%3C/text%3E%3C/svg%3E");
  opacity: 0.38;
  animation: floatSimple 70s infinite linear;
  transform-origin: center;
}

/* Academic icons layer - SIMPLIFIED */
.hero-academic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='800' height='800' viewBox='0 0 800 800' fill='none'%3E%3Cpath d='M400 120 L550 200 L400 280 L250 200 L400 120Z' fill='rgba(255,255,255,0.15)'/%3E%3Cpath d='M200 500 L200 600 L350 600 L350 500 L200 500Z M230 500 L230 450 L320 450 L320 500 L230 500Z' fill='rgba(255,255,255,0.15)'/%3E%3Cpath d='M550 350 L650 450 L550 550 L450 450 Z' stroke='rgba(255,255,255,0.15)' stroke-width='4' fill='none'/%3E%3C/svg%3E");
  opacity: 0.33;
  animation: floatSimple 90s infinite linear reverse;
  transform-origin: center;
}

/* Enhanced animation with scale and rotation */
@keyframes floatSimple {
  0% {
    background-position: 0% 0%;
    transform: scale(1) rotate(0deg);
  }
  50% {
    background-position: 50% 50%;
    transform: scale(1.08) rotate(1.5deg);
  }
  100% {
    background-position: 100% 100%;
    transform: scale(1) rotate(0deg);
  }
}

/* Position the content above the floating background */
.hero-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.hero h1 {
  font-size: 3.5rem;
  margin-bottom: var(--spacing-sm);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.hero h2 {
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: var(--spacing-lg);
  opacity: 0.9;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.cta-buttons {
  display: flex;
  gap: var(--spacing-md);
  justify-content: center;
}

.primary-cta, .secondary-cta {
  padding: var(--spacing-sm) var(--spacing-lg);
  border-radius: var(--border-radius);
  font-weight: 600;
  transition: var(--transition);
}

.primary-cta {
  background-color: var(--accent-color);
  color: white;
}

.primary-cta:hover {
  background-color: #000000;
  color: white;
  transform: translateY(-2px);
}

.secondary-cta {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  border: 2px solid white;
}

.secondary-cta:hover {
  background-color: white;
  color: var(--primary-color);
  transform: translateY(-2px);
}

/* Stats Section */
.stats {
  padding: var(--spacing-lg) 0;
  background-color: var(--bg-color);
  position: relative;
  border-bottom: 1px solid rgba(224, 224, 224, 0.5);
}

.stats .container {
  display: flex;
  justify-content: center;
  gap: var(--spacing-xl);
}

.stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.stat-number {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primary-color);
  margin-bottom: var(--spacing-xs);
}

.stat-text {
  font-size: 1.2rem;
  color: var(--text-light);
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Enroll Button */
.enroll-button-container {
  text-align: center;
  margin-top: var(--spacing-lg);
  padding-bottom: var(--spacing-lg);
}

.enroll-button {
  background-color: var(--primary-color);
  color: white;
  padding: var(--spacing-sm) var(--spacing-xl);
  border-radius: var(--border-radius);
  font-weight: 600;
  font-size: 1.1rem;
  display: inline-block;
  transition: var(--transition);
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.enroll-button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Features Section */
.features {
  padding: var(--spacing-xl) 0;
  background-color: var(--bg-color);
  position: relative;
  overflow: hidden;
}

.features::before {
  content: '';
  position: absolute;
  top: -100px;
  right: -100px;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(211, 47, 47, 0.03), rgba(211, 47, 47, 0.01));
  opacity: 0.4;
  z-index: 0;
}

.features::after {
  content: '';
  position: absolute;
  bottom: -150px;
  left: -150px;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(33, 33, 33, 0.02), rgba(33, 33, 33, 0.01));
  opacity: 0.3;
  z-index: 0;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  position: relative;
  z-index: 1;
}

.feature-card {
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  transition: all 0.3s ease;
  padding: var(--spacing-lg);
  text-align: center;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(183, 28, 28, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: var(--spacing-sm);
  background-color: rgba(211, 47, 47, 0.1);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto var(--spacing-md);
  transition: var(--transition);
}

.feature-icon svg {
  width: 40px;
  height: 40px;
  fill: var(--primary-color);
  filter: drop-shadow(0 2px 3px rgba(0, 0, 0, 0.1));
  transition: all 0.3s ease;
}

.feature-card:hover .feature-icon {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(183, 28, 28, 0.15);
}

.feature-card:hover .feature-icon svg {
  fill: var(--accent-color);
  transform: scale(1.1);
}

.feature-card:nth-child(1) .feature-icon {
  background-color: rgba(211, 47, 47, 0.08);
}

.feature-card:nth-child(2) .feature-icon {
  background-color: rgba(211, 47, 47, 0.12);
}

.feature-card:nth-child(3) .feature-icon {
  background-color: rgba(211, 47, 47, 0.16);
}

.feature-card h3 {
  font-size: 1.5rem;
  margin-bottom: var(--spacing-sm);
}

/* Programs Section */
.programs {
  padding: 80px 0;
  background-color: var(--bg-color);
}

.program-header-image-wrapper {
  max-width: 100%;
  margin: 0 auto 40px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  position: relative;
}

.image-caption {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: rgba(13, 71, 161, 0.9);
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
  letter-spacing: 0.5px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  z-index: 2;
  transform: translateY(0);
  transition: transform 0.3s ease, background-color 0.3s ease;
  border: 2px solid rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
}

.program-header-image-wrapper:hover .image-caption {
  transform: translateY(5px);
  background-color: rgba(0, 37, 103, 0.95);
}

.program-header-image {
  width: 100%;
  height: auto;
  display: block;
  transform: scale(1.01);
  transition: transform 0.6s ease;
}

.program-header-image-wrapper:hover .program-header-image {
  transform: scale(1.03);
}

.section-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
  text-align: center;
  font-weight: 600;
}

.programs::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: 
    radial-gradient(circle at 90% 10%, rgba(211, 47, 47, 0.02) 0%, transparent 100px),
    radial-gradient(circle at 10% 90%, rgba(33, 33, 33, 0.01) 0%, transparent 100px);
  opacity: 0.7;
  z-index: 0;
}

.programs-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-lg);
  position: relative;
  z-index: 1;
}

.program-card {
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: all 0.3s ease;
  padding: var(--spacing-lg);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.program-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(183, 28, 28, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.program-card h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: var(--spacing-sm);
}

.program-details {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-sm);
  margin-bottom: var(--spacing-sm);
}

.program-detail {
  background-color: rgba(245, 247, 250, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--border-radius);
  font-size: 0.9rem;
}

.program-detail span {
  font-weight: 600;
  margin-right: 5px;
}

.program-card h4 {
  font-size: 1.1rem;
  margin-top: var(--spacing-sm);
  margin-bottom: var(--spacing-xs);
  color: var(--text-color);
}

.program-card ul {
  margin-bottom: var(--spacing-md);
  padding-left: var(--spacing-md);
}

.program-card ul li {
  margin-bottom: var(--spacing-xs);
  position: relative;
  padding-left: var(--spacing-sm);
}

.program-card ul li:before {
  content: '•';
  color: var(--primary-color);
  position: absolute;
  left: 0;
}

.program-cta {
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: var(--spacing-xs) var(--spacing-md);
  border-radius: var(--border-radius);
  margin-top: auto;
  font-weight: 500;
  align-self: flex-start;
}

.program-cta:hover {
  background-color: var(--secondary-color);
  color: white;
}

/* Classroom Showcase Section */
.classroom-showcase {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.tech-heading {
  color: #333;
  margin-bottom: 25px;
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
}

.tech-description {
  margin-bottom: 40px;
  line-height: 1.7;
  color: #555;
  font-size: 1.1rem;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.classroom-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}

.tech-highlights {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.tech-feature {
  display: flex;
  align-items: center;
  gap: 15px;
}

.tech-icon {
  width: 45px;
  height: 45px;
  background: #4a90e2;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.tech-icon svg {
  width: 24px;
  height: 24px;
  fill: white;
}

.tech-feature h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
}

.classroom-image-container {
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0,0,0,0.15);
}

.classroom-image {
  width: 100%;
  height: auto;
  display: block;
}

.tech-badge {
  position: absolute;
  top: 20px;
  right: 20px;
  background: #4a90e2;
  color: white;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 30px;
}

@media (max-width: 992px) {
  .classroom-content {
    flex-direction: column-reverse;
  }
  
  .tech-highlights, 
  .classroom-image-container {
    width: 100%;
  }
}

/* Why Choose SEI Section */
.why-choose-sei {
  padding: var(--spacing-xl) 0;
  background-color: var(--bg-color);
  position: relative;
  overflow: hidden;
}

.why-choose-sei::before,
.why-choose-sei::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  animation: float 15s infinite ease-in-out;
}

.why-choose-sei::before {
  width: 200px;
  height: 200px;
  background: radial-gradient(circle, rgba(33, 33, 33, 0.02) 0%, rgba(255, 255, 255, 0) 70%);
  top: 10%;
  right: -100px;
}

.why-choose-sei::after {
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, rgba(33, 33, 33, 0.01) 0%, rgba(255, 255, 255, 0) 70%);
  bottom: 5%;
  left: -150px;
  animation-delay: -5s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-15px) translateX(10px);
  }
  50% {
    transform: translateY(0) translateX(20px);
  }
  75% {
    transform: translateY(15px) translateX(10px);
  }
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: var(--spacing-lg);
  margin-top: var(--spacing-xl);
  position: relative;
  z-index: 1;
}

.benefit-card {
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  padding: var(--spacing-lg);
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(33, 33, 33, 0.1);
  border: 1px solid rgba(224, 224, 224, 1);
}

.benefit-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: var(--accent-color);
}

.benefit-icon {
  font-size: 3rem;
  margin-bottom: var(--spacing-sm);
  color: var(--accent-color);
  background-color: rgba(33, 33, 33, 0.05);
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto var(--spacing-md);
}

/* Custom SVG icons styles */
.custom-icon {
  width: 40px;
  height: 40px;
  fill: var(--accent-color);
  transition: all 0.3s ease;
}

.benefit-card:hover .custom-icon {
  fill: var(--primary-color);
  transform: scale(1.1);
}

.benefit-card:nth-child(1) .benefit-icon,
.benefit-card:nth-child(2) .benefit-icon,
.benefit-card:nth-child(3) .benefit-icon,
.benefit-card:nth-child(4) .benefit-icon,
.benefit-card:nth-child(5) .benefit-icon,
.benefit-card:nth-child(6) .benefit-icon {
  background-color: rgba(33, 33, 33, 0.05);
}

.benefit-card h3 {
  font-size: 1.3rem;
  margin-bottom: var(--spacing-sm);
  color: var(--accent-color);
}

.benefit-card p {
  color: var(--text-light);
  font-size: 0.95rem;
  line-height: 1.6;
}

/* Responsive design for benefits grid */
@media (max-width: 900px) {
  .benefits-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .benefits-grid {
    grid-template-columns: 1fr;
  }
}

/* Testimonials Section */
.testimonials {
  padding: var(--spacing-xl) 0;
  background-color: var(--bg-color);
  position: relative;
  overflow: hidden;
}

.testimonials::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, 
    transparent 0%, 
    rgba(211, 47, 47, 0.1) 50%, 
    transparent 100%);
}

.testimonials::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, 
    transparent 0%, 
    rgba(211, 47, 47, 0.1) 50%, 
    transparent 100%);
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  position: relative;
  z-index: 1;
}

.testimonial-card {
  position: relative;
  background-color: white;
  border: 1px solid rgba(224, 224, 224, 0.5);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.03);
  transition: all 0.3s ease;
  border-radius: var(--border-radius);
  padding: var(--spacing-lg);
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(183, 28, 28, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.testimonial-content {
  margin-bottom: var(--spacing-md);
  position: relative;
}

.testimonial-content p {
  font-style: italic;
  color: var(--text-light);
}

.testimonial-content p::before,
.testimonial-content p::after {
  content: '"';
  font-size: 1.5rem;
  color: rgba(211, 47, 47, 0.7);
}

.testimonial-author h4 {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.testimonial-author p {
  color: var(--text-lighter);
  font-size: 0.9rem;
}

/* Contact Section Styles */
.contact {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-top: 40px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.contact-item:hover {
  transform: translateY(-5px);
}

.contact-icon {
  width: 24px;
  height: 24px;
  color: #007bff;
  flex-shrink: 0;
}

.contact-item h3 {
  margin: 0 0 5px 0;
  font-size: 1.1rem;
  color: #333;
}

.contact-item p {
  margin: 0;
  color: #666;
  line-height: 1.5;
}

.contact-form {
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.form-group {
  margin-bottom: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #007bff;
}

.form-group textarea {
  height: 120px;
  resize: vertical;
}

.submit-button {
  width: 100%;
  padding: 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background: #0056b3;
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.form-message {
  margin-top: 15px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.form-message.success {
  background: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.form-message.error {
  background: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.map-container {
  width: 100%;
  height: 100%;
  min-height: 450px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border: none;
}

/* Responsive Design */
@media (max-width: 992px) {
  .contact-container {
    grid-template-columns: 1fr;
  }
  
  .map-container {
    order: -1;
  }
}

@media (max-width: 576px) {
  .contact {
    padding: 60px 0;
  }
  
  .contact-form {
    padding: 20px;
  }
  
  .contact-item {
    padding: 15px;
  }
}

/* Founding Pioneers Section */
.founding-pioneers {
  padding: var(--spacing-xl) 0;
  background-color: var(--bg-color);
  position: relative;
}

.founding-pioneers::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(211, 47, 47, 0.02), rgba(211, 47, 47, 0.05));
  z-index: 0;
}

.pioneers-content {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: var(--spacing-xl);
  position: relative;
  z-index: 1;
}

.pioneers-image {
  flex: 1;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow);
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.pioneers-image:hover {
  box-shadow: 0 10px 25px rgba(211, 47, 47, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.8);
  transform: translateY(-5px);
}

.pioneers-image img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.pioneers-image:hover img {
  transform: scale(1.02);
}

.pioneers-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
  pointer-events: none;
}

.pioneers-text {
  flex: 1;
  padding-right: var(--spacing-lg);
}

.pioneers-text h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: var(--spacing-md);
  position: relative;
  padding-bottom: var(--spacing-sm);
}

.pioneers-text h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: var(--accent-color);
  border-radius: 2px;
}

.pioneers-text p {
  color: var(--text-light);
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: var(--spacing-md);
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .pioneers-content {
    flex-direction: column-reverse;
  }
  
  .pioneers-text {
    padding-right: 0;
    margin-bottom: var(--spacing-lg);
    text-align: center;
  }
  
  .pioneers-text h2::after {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .pioneers-image {
    max-width: 90%;
    margin: 0 auto;
  }
}

/* Enrollment Page Styles */
.enrollment {
  padding: 80px 0;
  background-color: #f9f9f9;
  min-height: calc(100vh - 200px);
}

.enrollment .container {
  max-width: 1000px;
  margin: 0 auto;
}

.enrollment-form {
  margin-top: 30px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  height: auto;
  overflow: visible;
}

.enrollment-form iframe {
  display: block;
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  border: none;
  overflow: visible;
}

/* Remove fixed heights for mobile devices */
@media (max-width: 768px) {
  .enrollment-form iframe {
    width: 100%;
    min-height: 100vh;
  }
}

/* Added specific mobile styles for the header */
@media screen and (max-width: 768px) {
  .main-header .container {
    padding: 0 15px;
    position: relative;
  }
  
  .logo-img {
    height: 50px;
  }
  
  .mobile-menu-toggle {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

/* Scholarships Section */
.scholarships {
  @apply py-16 bg-white border-b border-gray-100;
  padding: 80px 0;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.scholarship-image-wrapper {
  max-width: 1000px;
  margin: 0 auto 40px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.scholarship-image-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(211, 47, 47, 0.15);
}

.scholarship-header-image {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.5s ease;
}

.scholarship-image-wrapper:hover .scholarship-header-image {
  transform: scale(1.02);
}

.scholarship-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(211, 47, 47, 0.9), transparent);
  padding: 30px 20px 20px;
  color: white;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: center;
}

.scholarship-content {
  @apply max-w-4xl mx-auto;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.scholarship-title {
  @apply text-3xl font-semibold mb-12 text-center text-red-700;
  font-size: 38px;
  font-weight: 600;
  color: var(--primary-color);
  margin-bottom: 40px;
  letter-spacing: -0.5px;
}

.scholarship-grid {
  @apply grid gap-10 mb-8 text-left;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  margin-bottom: 32px;
  text-align: left;
}

.scholarship-block {
  @apply bg-white rounded-lg shadow-md p-6 border border-gray-200;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  padding: 24px;
  border: 1px solid #f0f0f0;
}

.scholarship-block h3 {
  @apply text-2xl font-medium mb-6 text-gray-800;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 24px;
  color: #333;
}

.table-heading {
  @apply mb-4;
  margin-bottom: 16px;
}

.table-heading h4 {
  @apply text-lg font-medium text-gray-700;
  font-size: 18px;
  font-weight: 500;
  color: #444;
}

.scholarship-table {
  @apply w-full mb-6 border-collapse;
  width: 100%;
  margin-bottom: 24px;
  border-collapse: collapse;
}

.table-row {
  @apply border-b border-gray-200 flex;
  display: flex;
  border-bottom: 1px solid #f0f0f0;
}

.table-header {
  @apply bg-gray-50 font-semibold;
  background-color: #f9f9f9;
  font-weight: 600;
}

.table-cell {
  @apply py-3 px-4 flex-1;
  padding: 12px 16px;
  flex: 1;
}

.table-cell.highlight {
  @apply font-semibold text-red-600 text-right;
  font-weight: 600;
  color: var(--primary-color);
  text-align: right;
}

.scholarship-note {
  @apply text-sm text-gray-600 mb-6;
  font-size: 14px;
  color: #666;
  margin-bottom: 24px;
}

.model-test-description {
  @apply mb-6 text-gray-700;
  margin-bottom: 24px;
  color: #555;
}

.cash-prizes {
  @apply flex justify-around mb-8 py-4 bg-red-50 rounded-lg;
  display: flex;
  justify-content: space-around;
  margin-bottom: 32px;
  padding: 16px 0;
  background-color: rgba(211, 47, 47, 0.05);
  border-radius: 8px;
}

.cash-prize {
  @apply flex flex-col items-center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prize-position {
  @apply text-gray-700 font-medium mb-1;
  color: #555;
  font-weight: 500;
  margin-bottom: 4px;
}

.prize-amount {
  @apply text-xl font-bold text-red-600;
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-color);
}

.talent-award-description {
  @apply text-gray-700;
  color: #555;
}

@media (min-width: 768px) {
  .scholarship-grid {
    @apply grid-cols-2;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .scholarship-title {
    font-size: 28px;
  }
  
  .scholarship-image-wrapper {
    margin: 0 auto 30px;
    border-radius: 8px;
  }
  
  .scholarship-image-overlay {
    padding: 20px 15px 15px;
    font-size: 1.1rem;
  }
  
  .scholarship-block {
    padding: 20px 16px;
  }
  
  .table-row {
    flex-direction: column;
  }
  
  .table-cell {
    padding: 8px 0;
  }
  
  .table-cell.highlight {
    text-align: left;
  }
  
  .cash-prizes {
    flex-direction: column;
    gap: 16px;
  }
}

/* Publications Section */
.publications {
  padding: 80px 0;
  background-color: #f8f9fa;
  position: relative;
}

.publications-layout {
  display: flex;
  align-items: center;
  gap: 40px;
  max-width: 1100px;
  margin: 0 auto;
}

.publications-text {
  flex: 1;
}

.publications-description {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 40px;
}

.publications-highlight {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
  gap: 20px;
}

.publications-icon {
  flex: 0 0 60px;
}

.publications-icon svg {
  width: 60px;
  height: 60px;
  fill: var(--primary-color);
}

.publications-highlight p {
  flex: 1;
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin: 0;
  line-height: 1.7;
}

.publications-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.books-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.publications-image:hover .books-image {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.2);
}

@media (max-width: 992px) {
  .publications-layout {
    flex-direction: column;
  }
  
  .publications-text,
  .publications-image {
    width: 100%;
  }
  
  .publications-image {
    margin-top: 30px;
    order: -1;
  }
  
  .books-image {
    max-width: 80%;
  }
}

@media (max-width: 768px) {
  .publications-highlight {
    flex-direction: column;
    padding: 20px 15px;
    text-align: center;
  }
  
  .publications-highlight p {
    text-align: center;
    margin-top: 15px;
  }
  
  .books-image {
    max-width: 100%;
  }
}

/* Location Change Alert */
.location-change-alert {
  background-color: var(--primary-color);
  color: white;
  padding: 12px 0;
  position: relative;
  z-index: 1001;
}

.alert-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 1rem;
  text-align: center;
}

.alert-icon {
  width: 24px;
  height: 24px;
  fill: white;
  flex-shrink: 0;
}

.alert-content p {
  margin: 0;
  font-weight: 500;
}

.alert-content strong {
  font-weight: 700;
  letter-spacing: 0.5px;
}

.location-details-btn {
  background-color: white;
  color: var(--primary-color);
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.location-details-btn:hover {
  background-color: var(--accent-color);
  color: white;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .alert-content {
    flex-direction: column;
    gap: 8px;
    padding: 5px 0;
  }
  
  .location-details-btn {
    margin-top: 5px;
  }
}

.location-highlight {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.95);
  border-left: 4px solid var(--primary-color);
  border-radius: 6px;
  padding: 12px 20px;
  margin: 20px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-width: 700px;
  text-align: center;
}

.location-icon {
  width: 24px;
  height: 24px;
  fill: var(--primary-color);
  margin-right: 15px;
  flex-shrink: 0;
}

.location-highlight p {
  margin: 0;
  color: #333;
  font-size: 1.1rem;
  line-height: 1.4;
}

.floating-location-badge {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  color: white;
  padding: 10px 16px;
  border-radius: 30px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: pulse 2s infinite;
}

.floating-location-badge:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
  animation: none;
}

.floating-badge-icon {
  width: 20px;
  height: 20px;
  fill: white;
  margin-right: 10px;
  flex-shrink: 0;
}

.floating-badge-text {
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .floating-location-badge {
    bottom: 15px;
    right: 15px;
    padding: 8px 12px;
  }
  
  .floating-badge-text {
    font-size: 0.8rem;
  }
}

/* Apply Section Styles */
.apply {
  padding: 80px 0;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  text-align: center;
}

.apply .section-description {
  max-width: 800px;
  margin: 0 auto 40px;
  font-size: 1.2rem;
  color: #495057;
  line-height: 1.6;
}

.apply-benefits {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 40px auto;
  max-width: 900px;
}

.apply-benefits .benefit {
  flex: 1;
  min-width: 280px;
  max-width: 320px;
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  text-align: left;
}

.apply-benefits .benefit:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.benefit-icon {
  width: 24px;
  height: 24px;
  color: #0052cc;
  flex-shrink: 0;
  margin-top: 2px;
}

.benefit-content {
  flex: 1;
}

.benefit-title {
  font-weight: 600;
  color: #1a1a1a;
  font-size: 1.1rem;
  margin-bottom: 8px;
  display: block;
}

.benefit-description {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
}

.apply-cta {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: #0052cc;
  color: white;
  padding: 16px 32px;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.apply-cta:hover {
  background: #004099;
  transform: translateY(-2px);
  color: white;
}

.arrow-icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  transition: transform 0.2s ease;
}

.apply-cta:hover .arrow-icon {
  transform: translateX(4px);
}

@media (max-width: 768px) {
  .apply {
    padding: 60px 20px;
  }

  .apply-benefits {
    flex-direction: column;
    align-items: center;
  }

  .apply-benefits .benefit {
    width: 100%;
    max-width: 100%;
  }
}
